<template>
  <!-- Sample View -->
  <j-page
    title="Sample"
    class="sample"
  >
    <!-- Forms -->
    <v-col cols="12">
      <h3 class="display-2">
        Forms
      </h3>
    </v-col>

    <v-col>
      <j-form :model="form">
        <!-- Status / String / Placeholder -->
        <j-control
          name="form_status"
          cols="4"
        />
        <j-control
          name="string"
          cols="4"
          :disabled="formDisabled"
          :display="formDisplay"
          :autofocus="true"
        />
        <j-control
          name="placeholder_string"
          :disabled="formDisabled"
          cols="4"
        />
        <!-- Text Areas -->
        <j-control
          name="textarea"
          :disabled="formDisabled"
          :display="formDisplay"
          rows="3"
          cols="4"
        />
        <j-control
          name="autogrow"
          :disabled="formDisabled"
          :display="formDisplay"
          rows="2"
          cols="4"
        />
        <j-control
          name="string"
          cols="4"
          label="Sizing / Comparison"
        />
        <!-- Select / Color / File-->
        <j-control
          name="select"
          :disabled="formDisabled"
          :display="formDisplay"
          cols="2"
        />
        <j-control
          name="color"
          :disabled="formDisabled"
          :display="formDisplay"
          cols="2"
        />
        <j-control
          name="file"
          :disabled="formDisabled"
          :display="formDisplay"
          cols="4"
        />
        <j-control
          name="string"
          cols="4"
          label="Sizing / Comparison"
        />
        <!-- Date / Tags -->
        <j-control
          name="date"
          :disabled="formDisabled"
          :display="formDisplay"
          cols="4"
        />
        <j-control
          name="tags"
          :disabled="formDisabled"
          :display="formDisplay"
          cols="4"
        />
        <j-control
          name="string"
          cols="4"
          label="Sizing / Comparison"
        />
        <!-- Switch / Checkbox / Autocomplete -->
        <j-control
          name="switch"
          :disabled="formDisabled"
          :display="formDisplay"
          cols="2"
        />
        <j-control
          name="checkbox"
          :disabled="formDisabled"
          :display="formDisplay"
          cols="2"
        />
        <j-control
          name="autocomplete"
          :disabled="formDisabled"
          :display="formDisplay"
          cols="4"
        />
        <j-control
          name="string"
          cols="4"
          label="Sizing / Comparison"
        />
        <!-- File -->
        <j-control
          name="file"
          :disabled="formDisabled"
          :display="formDisplay"
        />
      </j-form>
    </v-col>
  </j-page>
</template>

<script>

import SampleForm from '@/models/SampleForm'

export default {
  name: 'SampleView',
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Sample'
    }
  },
  data() {
    return {
      form: new SampleForm()
    }
  },
  computed: {
    formDisabled () {
      return this.form.form_status === 'Disable'
    },
    formDisplay () {
      return this.form.form_status === 'Display'
    }
  },
  created () {
    // Expired session testing
    // let jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1NjUzNjA3ODQsInN1YiI6MSwiZW1haWwiOiJpYW5AaW5rMTkuY29tIiwiYWRtaW4iOmZhbHNlfQ.Kt7Z0oRymAjEcd09KEMFIP_wEhxCTB02xA4PGZZXlP8"
    // this.$store.dispatch ('setJWT', jwt)
    try {
      this.form.validate()
    } catch (error) {
      // console.log('Sample Error', error)
    }
  },
  methods: {
  }
}
</script>
