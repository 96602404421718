import BModel from '@/models/base/BModel'
import ContactList from './ContactList'
import {
  required
} from 'vue-mc/validation'

export default class SampleForm extends BModel {

  get _manifest() {
    return {
      form_status: {
        label: 'Form Status',
        type: 'select',
        options: ['Normal', 'Disable', 'Display'],
        default: 'Normal',
      },
      string: {
        label:"String",
        default: 'A brief string',
        hint: 'A hint for the string field'
      },
      placeholder_string: {
        label: 'Placeholder',
        placeholder: 'Some placeholder text'
      },
      textarea: {
        label: 'Text Area (Markdown)',
        type: 'markdown',
        default: "This is a **longer piece** of text.\n\nIt spans multiple lines.",
        hint: 'A hint for the text area'
      },
      autogrow: {
        label: 'Auto-grow Text Area',
        type: 'autogrow',
        default: "This should **expand** as text is added",
        hint: 'A hint for the text area'
      },
      select: {
        label: 'Select',
        type: 'select',
        options: ['One','Two','Three'],
        default: 'Two',
        hint: 'A hint for the select'
      },
      color: {
        label: 'Color',
        type: 'color',
        default: "#F9a862",
        hint: 'A hint for the color'
      },
      date: {
        label: 'Date',
        type: 'date',
        default: new Date('November 5, 2020 10:00:00'),
        hint: 'A hint for the date'
      },
      switch: {
        label: 'Switch',
        type: 'switch',
        default: true,
        hint: 'A hint for the switch'
      },
      checkbox: {
        label: 'Check',
        type: 'boolean',
        default: true,
        hint: 'A hint for the checkbox'
      },
      tags: {
        label: 'Tags',
        type: 'tags',
        default: ['one','two','three'],
        hint: 'A hint for the tags'
      },
      autocomplete: {
        label: 'Autocomplete',
        type: 'autocomplete',
        default: 'ian-koss',
        options: {
          text: 'name',
          value: 'slug',
          items: this.getStaff,
          watch: false
        }
      },
      file: {
        label: 'File',
        type: 'file',
        hint: 'A hint for the file'
      },
      required_string: {
        label: 'Required Field (show validation error)',
        hint: 'A hint for the required string',
        validation: required
      }
    }
  }

  async getStaff() {
    let itemList = new ContactList([], {}, { listing: 'staff', current_rows: 0 })
    await itemList.fetch()
    return itemList.getModels()
  }
}